import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkSummaryFetching, getSummaryData, getSummaryLastUpdateDate } from "selectors/summary";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Businesses from "utils/Businesses";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import GoogleBackupButton from "nlib/common/GoogleBackupButton";
import NoDataContent from "nlib/common/NoDataContent";
import OrganizationsActions from "actions/OrganizationsActions";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import SummaryActions from "actions/SummaryActions";
import SummaryFilter from "./lib/SummaryFilter";
import SummaryTable from "./lib/SummaryTable";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const SummaryPage = () => {
  const dispatch = useDispatch();

  const [{ businessName }] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const lastUpdatedAt = useSelector(getSummaryLastUpdateDate);

  const fetchingData = useSelector(checkSummaryFetching);

  const summaryData = useSelector(getSummaryData);

  const { name: organizationName } = useSelector(getActiveOrganization);

  const regularBusinessesData = useMemo(() => {
    return summaryData.filter(({ business: { extraData } }) => !Businesses.checkIsRpaMode(extraData));
  }, [summaryData]);

  const rpaBusinessesData = useMemo(() => {
    return summaryData.filter(({ business: { extraData } }) => Businesses.checkIsRpaMode(extraData));
  }, [summaryData]);

  const subTitle = useMemo(() => {
    return lastUpdatedAt
      ? `${uiTexts.updated}: ${moment.utc(lastUpdatedAt).local().format(Constants.DATETIME_FORMATS.DATETIME_TEXT)}`
      : organizationName;
  }, [lastUpdatedAt, uiTexts, organizationName]);

  const [regularBusinessesExpanded, setRegularBusinessesExpanded] = useState(true);

  const [rpaBusinessesExpanded, setRpaBusinessesExpanded] = useState(true);

  const handleAddBusinessButtonClick = useCallback(() => {
    dispatch(UiActions.showAddBusinessWindow());
  }, [dispatch]);

  const handleRegularBusinessesTitleClick = useCallback(() => {
    setRegularBusinessesExpanded((value) => !value);
  }, []);

  const handleRpaBusinessesTitleClick = useCallback(() => {
    setRpaBusinessesExpanded((value) => !value);
  }, []);

  useLayoutEffect(() => {
    dispatch(SummaryActions.fetchSummaryData({ businessName }));

    return Utils.setInterval(
      async() => {
        await dispatch(OrganizationsActions.fetchUsersList(true, true));
        await dispatch(BusinessesActions.fetchBusinessesList(false, true, true));
        await dispatch(SummaryActions.fetchSummaryData({ businessName }, true, true));
      },
      Constants.BUSINESSES_DATA_UPDATE_INTERVAL
    );
  }, [dispatch, businessName]);

  return (
    <Page className={Css.summaryPage}>
      <PageContent>
        <div className={Css.pageHeader}>
          <div className={Css.leftBlock}>
            <div className={Css.subTitle}>{subTitle}</div>
            <div className={Css.title}>
              <Icons.Briefcase />
              <span>{uiTexts.businesses}</span>
            </div>
          </div>
          <div className={Css.rightBlock}>
            <SummaryFilter disabled={fetchingData} />
            <GoogleBackupButton disabled={fetchingData} />
            {!!summaryData.length && (
              <Button
                large primary
                disabled={fetchingData}
                className={Css.button}
                icon={Icons.Plus}
                onClick={handleAddBusinessButtonClick}>
                <span>{uiTexts.addNewBusiness}</span>
              </Button>
            )}
          </div>
        </div>
        {fetchingData
          ? <div className={classNames(Css.card, Css.preloader)}><Preloader absolute /></div>
          : (summaryData.length
            ? (
              <>
                {!!rpaBusinessesData.length && (
                  <div className={Css.card}>
                    <div
                      className={classNames(Css.title, !rpaBusinessesExpanded && Css.collapsed)}
                      onClick={handleRpaBusinessesTitleClick}>
                      {rpaBusinessesExpanded ? <Icons.CaretDown /> : <Icons.CaretRight />}
                      <span>{`${uiTexts.roboticAiBookkeeper} (${rpaBusinessesData.length})`}</span>
                    </div>
                    {rpaBusinessesExpanded && (
                      <SummaryTable
                        rpaMode
                        data={rpaBusinessesData}
                        disabled={fetchingData} />
                    )}
                  </div>
                )}
                {!!regularBusinessesData.length && (
                  <div className={Css.card}>
                    <div
                      className={classNames(Css.title, !regularBusinessesExpanded && Css.collapsed)}
                      onClick={handleRegularBusinessesTitleClick}>
                      {regularBusinessesExpanded ? <Icons.CaretDown /> : <Icons.CaretRight />}
                      <span>{`${uiTexts.dataEntryAutomationHub} (${regularBusinessesData.length})`}</span>
                    </div>
                    {regularBusinessesExpanded && (
                      <SummaryTable
                        data={regularBusinessesData}
                        disabled={fetchingData} />
                    )}
                  </div>
                )}
              </>
            )
            : (
              <div className={classNames(Css.card, Css.noDataContent)}>
                <NoDataContent
                  icon={Icons.Briefcase}
                  title={uiTexts.noBusinesses}>
                  {!summaryData.length && (
                    <Button
                      large primary
                      icon={Icons.Plus}
                      onClick={handleAddBusinessButtonClick}>
                      {uiTexts.addNewBusiness}
                    </Button>
                  )}
                </NoDataContent>
              </div>
            ))}
      </PageContent>
    </Page>
  );
};

export default React.memo(SummaryPage);
